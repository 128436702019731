import React from "react";
import { gateHexagrams } from "../hexagramComponents/gates.hexagrams";
import { defineLineToColor } from "../hexagramComponents/utils";

export const HexagramChartComponent = ({
  gate,
  line,
  exaltedOrDetriment,
  transform,
  width,
  height,
  isLast,
  defaultColor,
  multipleLinesToColor = [],
  matchGate,
}) => {
  const { top: TopComponent, bottom: BottomComponent } =
    gateHexagrams[gate] || {};
  const lineToColor = defineLineToColor(line);

  const linesToColorBottom = multipleLinesToColor
    .map((arr) => defineLineToColor(arr.line))
    .map((el) => el.bottomLine)
    .filter(Boolean);
  const linesToColorTop = multipleLinesToColor
    .map((arr) => defineLineToColor(arr.line))
    .map((el) => el.topLine)
    .filter(Boolean);

  return (
    <g transform={transform}>
      <TopComponent
        maxWidth={width}
        maxHeight={height}
        line={lineToColor.topLine}
        exaltedOrDetriment={
          exaltedOrDetriment
            ? exaltedOrDetriment
            : multipleLinesToColor.map((el) => el.exaltedOrDetriment)
        }
        defaultColor={defaultColor}
        multipleLinesToColor={linesToColorTop}
      />
      <BottomComponent
        maxWidth={width}
        maxHeight={height}
        line={lineToColor.bottomLine}
        exaltedOrDetriment={
          exaltedOrDetriment
            ? exaltedOrDetriment
            : multipleLinesToColor.map((el) => el.exaltedOrDetriment)
        }
        isSecond
        defaultColor={defaultColor}
        multipleLinesToColor={linesToColorBottom}
      />
      {isLast ? (
        <text
          x={gate < 10 ? "10" : "0"}
          y="-10"
          fontSize={width - 5}
          fill={matchGate ? "black" : "#D3D3D3"}
        >
          {gate}
        </text>
      ) : null}
    </g>
  );
};
