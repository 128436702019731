export const reverseArraysInObject = (obj) => {
  const reversedObj = {};

  for (const [key, value] of Object.entries(obj)) {
    reversedObj[key] = {};
    for (const [subKey, array] of Object.entries(value)) {
      reversedObj[key][subKey] = array.slice().reverse(); // Use slice to avoid mutating the original array
    }
  }

  return reversedObj;
};
