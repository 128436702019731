import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HexagramPicker from "../components/HexagramPicker";
import HexagramPickerNoPlanet from "../components/HexagramPickerNoPlanet";

export default function EducationalAspectsList() {
  const location = useLocation();
  const navigate = useNavigate();

  const { period, data, startDate, endDate, timeText, index } =
    location.state || {};
  console.log(endDate);
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="mt-10 space-y-4">
      <div className="flex flex-col items-center justify-center space-y-2">
        <button onClick={goBack} className="text-blue-500 hover:underline">
          {"<-"}Назад
        </button>
        <label className="block text-lg font-medium text-gray-700 text-center border-b border-black">
          {period}
        </label>
      </div>
      <div
        className="flex justify-start items-center space-x-2"
        key={`educational-aspectsList-${data.header}`}
      >
        <HexagramPickerNoPlanet value={data.value.split("-")[0]} />
        <div> - </div>
        <HexagramPickerNoPlanet value={data.value.split("-")[1]} />
        <label className="block text-lg font-medium text-gray-700 text-center">
          {data.header}
        </label>
      </div>
      <div className="text-lg font-medium text-gray-700">
        {`В периода от ${startDate} ${endDate}, ${data.text}`}
      </div>
      <div className="text-lg font-medium text-gray-700">
        {" "}
        Вашите възможности за преодоляване:{" "}
      </div>
      <div className="text-lg font-medium text-gray-700">
        {`${data.combo.text}`}
      </div>
      <div className="text-lg font-medium text-gray-700">
        {" "}
        Пример за житейска аналогия:{" "}
      </div>
      <div className="text-lg font-medium text-gray-700">
        {`${data.combo.analogicalText}`}
      </div>
    </div>
  );
}
