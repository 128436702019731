export const dailyReadHoursDisplay = (from, to) => {
  if (to) {
    if (
      from.split(" ")[1].split(":")[0] === "00" &&
      (from.split(" ")[1].split(":")[1] === "00" ||
        from.split(" ")[1].split(":")[1] === "01")
    ) {
      return `от началото на деня до ${to.split(" ")[1]}`;
    } else if (from.split(" ")[0] === to.split(" ")[0]) {
      return `от ${from.split(" ")[1]} до ${to.split(" ")[1]}`;
    } else {
      return `${from.split(" ")[1]} - до края на деня`;
    }
  } else {
    if (
      from.split(" ")[1].split(":")[0] === "00" &&
      (from.split(" ")[1].split(":")[1] === "00" ||
        from.split(" ")[1].split(":")[1] === "01")
    ) {
      return "цял ден";
    }
    return `${from.split(" ")[1]} - до края на деня`;
  }
};
