import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HexagramPicker from "../components/HexagramPicker";

export default function AspectsList() {
  const location = useLocation();
  const navigate = useNavigate();

  const { period, data, startDate, endDate, timeText, index } =
    location.state || {};

  const defineTimeTextObj = () => {
    switch (timeText) {
      case "now":
        return {
          generalText: data.text[index].generalText,
          header: data.text[index].generalTextHeaderPast,
        };
      case "future":
        return {
          generalText: data.text[index].generalText,
          header: data.text[index].generalTextHeaderPast,
        };
      case "before":
        return {
          generalText: data.text[index].generalTextPast,
          header: data.text[index].generalTextHeaderNegativePast,
        };
      default:
        return {};
    }
  };

  const timeTextObj = defineTimeTextObj();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="mt-10 space-y-4">
      <div className="flex flex-col items-center justify-center space-y-2">
        <button onClick={goBack} className="text-blue-500 hover:underline">
          {"<-"}Назад
        </button>
        <label className="block text-lg font-medium text-gray-700 text-center border-b border-black">
          {period}
        </label>
      </div>
      <div
        className="flex justify-start items-center space-x-2"
        key={`aspectsList-${timeTextObj.header}`}
      >
        <HexagramPicker value={data.gateLineDetriment} />
        <label className="block text-lg font-medium text-gray-700 text-center">
          {timeTextObj.header}
        </label>
      </div>
      <div className="text-lg font-medium text-gray-700">
        {`В периода от ${startDate} ${endDate}, ${timeTextObj.generalText}`}
      </div>
    </div>
  );
}
