import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HexagramPicker from "../components/HexagramPicker";
import HexagramComponent from "../components/HexagramComponent";

export default function DailyReadDetails() {
  const location = useLocation();
  const navigate = useNavigate();

  const { period, data, gateLineAndDetriment, oppositeText } =
    location.state || {};

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="mt-10 space-y-4">
      <div className="flex flex-col items-center justify-center space-y-2">
        <button onClick={goBack} className="text-blue-500 hover:underline">
          {"<-"}Назад
        </button>
        <label className="block text-lg font-medium text-gray-700 text-center border-b border-black">
          {period}
        </label>
      </div>
      <div
        className="flex justify-start items-center space-x-2"
        key={`daily-details-${data.generalTextHeaderNegative}`}
      >
        <HexagramPicker value={gateLineAndDetriment} />
        <label className="block text-lg font-medium text-gray-700 text-center">
          {data.generalTextHeaderNegative}
        </label>
      </div>
      <div className="text-lg font-medium text-gray-700">
        {`${period.includes("Утре") ? "Утре" : "Днес"}, ${data.generalText}`}
      </div>
      {oppositeText ? (
        <div className="space-y-2">
          <div className="text-lg font-medium text-gray-700">
            {" "}
            Вашите възможности за преодоляване:{" "}
          </div>
          {oppositeText.map((opposite) => (
            <div className="space-y-2">
              <div className="flex items-center justify-center flex-col space-x-2">
                <HexagramComponent
                  gate={+opposite.oppositeValue.split(".")[0]}
                  line={+opposite.oppositeValue.split(".")[1]}
                  exaltedOrDetriment={+opposite.oppositeValue.split(".")[2]}
                />
                {opposite.oppositeValue.split(".")[0]}
              </div>
              <div className="text-lg font-medium text-gray-700">
                {" "}
                {opposite.text}{" "}
              </div>
              <div className="text-lg font-medium text-gray-700">
                {" "}
                Пример за житейска аналогия:{" "}
              </div>
              <div className="text-lg font-medium text-gray-700">
                {" "}
                {opposite.analogicalText}{" "}
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
