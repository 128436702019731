import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HexagramPicker from "../components/HexagramPicker";
import { dailyReadHoursDisplay } from "../utils/dailyReadHoursDisplay";

const DailyRead = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { period, data, date } = location.state || {};
  const goBack = () => {
    navigate(-1);
  };

  const handleClick = (name, value, data, oppositeText) => {
    navigate(`/dailyRead/${name}`, {
      state: {
        period,
        data,
        gateLineAndDetriment: value,
        oppositeText,
      },
    });
  };

  return (
    <div className="mt-10 space-y-4">
      <div className="flex flex-col items-center justify-center space-y-2">
        <button onClick={goBack} className="text-blue-500 hover:underline">
          {"<-"}Назад
        </button>
        <label className="block text-lg font-medium text-gray-700 text-center">
          {period}
        </label>
      </div>
      {data.data.map((item) => (
        <div className="flex justify-start items-start flex-col">
          {item.map((value, index) => (
            <>
              <div>Интензитет: {value.percentage} %</div>
              <div className="flex justify-start items-center flex-row pb-10">
                {" "}
                <HexagramPicker value={value.valueFrom} />
                <div className="flex justify-start items-start flex-col">
                  <div
                    key={value.from + "-daily-" + date}
                    className="text-lg font-medium text-gray-700"
                  >
                    {" "}
                    {dailyReadHoursDisplay(value.from, value.to)}
                  </div>
                  <div className="cursor-pointer text-lg font-medium text-gray-700">
                    {" "}
                    {value.text.text.map((t) => (
                      <div
                        key={
                          value.from + "-daily-" + t.generalTextHeaderNegative
                        }
                        onClick={() =>
                          handleClick(
                            t.generalTextHeaderNegative,
                            value.valueFrom,
                            t,
                            value.oppositeText
                              ? value.oppositeText.filter(
                                  (v) => v.value === t.value
                                )
                              : undefined
                          )
                        }
                      >
                        {t.generalTextHeaderNegative + "->"}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DailyRead;
