import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HexagramPicker from "../components/HexagramPicker";
import { HexagramChart } from "../components/HexagramChart";
import { mapRaveToHexagramChartData } from "../utils/mapRaveToHexagramChartData";
import HexagramPickerNoPlanet from "../components/HexagramPickerNoPlanet";

const DetailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    period,
    data,
    prevDate,
    startDate,
    endDate,
    timeText,
    rave,
    startDateHoursMins,
    educational,
  } = location.state || {};
  const maxPriority = Math.max(...data.map((item) => item.priority));

  const sortedByPriority = data
    .sort((a, b) => b.priority - a.priority)
    .map((item) => {
      return {
        ...item,
        priorityPercentage: (item.priority / maxPriority) * 100,
      };
    });
  const hexagramChartData = mapRaveToHexagramChartData(rave);

  const defineTimeTextHeader = (data) => {
    switch (timeText) {
      case "now":
        return data.generalTextHeaderNegative;
      case "future":
        return data.generalTextHeaderNegative;
      case "before":
        return data.generalTextHeaderNegativePast;
      default:
        return {};
    }
  };

  const handleClick = (name, value, index) => {
    navigate(`/aspects/${name}`, {
      state: {
        period,
        data: sortedByPriority.find(
          (obj) =>
            obj.gateLineDetriment === value &&
            obj.text[0].generalTextHeaderNegative === name
        ),
        prevDate: prevDate,
        startDate: startDate,
        endDate: endDate,
        timeText,
        index: 0,
      },
    });
  };

  const handleEducationalClick = (name, value) => {
    navigate(`/educationalAspects/${name}`, {
      state: {
        period,
        data: educational.find((obj) => obj.value === value),
        prevDate: prevDate,
        startDate: startDate,
        endDate: endDate,
        timeText,
        index: 0,
      },
    });
  };

  const handleDetailsClick = () => {
    navigate(`/chartDetails`, {
      state: {
        data: hexagramChartData,
        transitDate: startDateHoursMins,
      },
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="mt-10 space-y-4">
      <div className="flex flex-col items-center justify-center space-y-2">
        <button onClick={goBack} className="text-blue-500 hover:underline">
          {"<-"}Назад
        </button>
        <label className="block text-lg font-medium text-gray-700 text-center">
          {period}
        </label>
        <div className="w-full h-full lg:w-3/5 lg:h-3/5">
          <HexagramChart
            width={"100%"}
            height={"100%"}
            data={hexagramChartData}
          />
        </div>
        <button
          onClick={handleDetailsClick}
          className="text-blue-500 hover:underline"
        >
          Детайли на картата{"->"}
        </button>
      </div>

      {sortedByPriority.map((item) => (
        <>
          <div>Интензитет: {item.priorityPercentage.toFixed(2)} %</div>
          <div
            className="flex justify-start items-center space-x-2"
            key={`detailPage-${item.value}`}
          >
            <HexagramPicker value={item.gateLineDetriment} />
            <div className="flex justify-start items-start flex-col">
              {item.text.map((text, index) => (
                <div
                  key={text.header}
                  className="cursor-pointer text-lg font-medium text-gray-700"
                  onClick={() =>
                    handleClick(
                      text.generalTextHeaderNegative,
                      item.gateLineDetriment,
                      index
                    )
                  }
                >
                  {defineTimeTextHeader(text)} {"->"}
                </div>
              ))}
            </div>
          </div>
        </>
      ))}
      {educational ? (
        <label className="block text-lg font-medium text-gray-700 text-center">
          Обучителни:
        </label>
      ) : null}
      {educational
        ? educational.map((item) => (
            <div
              className="flex justify-start items-center space-x-2"
              key={`detailPage-${item.value}`}
            >
              <HexagramPickerNoPlanet value={item.value.split("-")[0]} />
              <div> - </div>
              <HexagramPickerNoPlanet value={item.value.split("-")[1]} />
              <div
                key={item.header}
                className="cursor-pointer text-lg font-medium text-gray-700"
                onClick={() => handleEducationalClick(item.header, item.value)}
              >
                {item.header} {"->"}
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default DetailPage;
