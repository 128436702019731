import React from "react";
import { gateHexagrams } from "../hexagramComponents/gates.hexagrams";
import { defineLineToColor } from "../hexagramComponents/utils";

export default function HexagramComponent({
  gate,
  line,
  exaltedOrDetriment,
  width,
  height,
}) {
  const { top: TopComponent, bottom: BottomComponent } =
    gateHexagrams[gate] || {};

  const lineToColor = defineLineToColor(line);

  const maxWidth = 50;
  const maxHeight = 50;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <svg
        width={width ? width : maxWidth}
        height={height ? height : maxHeight}
        xmlns="http://www.w3.org/2000/svg"
      >
        <TopComponent
          maxWidth={width ? width : maxWidth}
          maxHeight={height ? height : maxHeight}
          line={lineToColor.topLine}
          exaltedOrDetriment={exaltedOrDetriment}
        />
        <BottomComponent
          maxWidth={width ? width : maxWidth}
          maxHeight={height ? height : maxHeight}
          line={lineToColor.bottomLine}
          exaltedOrDetriment={exaltedOrDetriment}
          isSecond
        />
      </svg>
    </div>
  );
}
