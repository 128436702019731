import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Spinner } from "react-bootstrap";

const BirthForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    day: "",
    month: "",
    year: "",
    hours: "",
    minutes: "",
    country: "Bulgaria",
    city: "Sofia",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [raveRead, setRaveRead] = useState(undefined);
  const [monthRead, setMonthRead] = useState({
    upcomingMonth: undefined,
    lastMonth: undefined,
    prevDate: undefined,
    currDate: undefined,
    nextDate: undefined,
    currDateRave: undefined,
    prevDateRave: undefined,
  });

  const handlePriorityChannels = async (e) => {
    e.preventDefault();

    const result = {
      personDate: `${formData.year}-${formData.month}-${formData.day} ${formData.hours}:${formData.minutes}`,
      lng: 42.6977,
      lat: 23.3219,
    };

    try {
      const response = await axios.post(
        "https://y68xeu846l.execute-api.eu-central-1.amazonaws.com/dev/admin/rave/read",
        result
      );
      setRaveRead(response.data.final);

      //   console.log("POST request response:", response.data);
    } catch (error) {
      console.error("Error sending POST request:", error);
    }
  };

  const handleMonthRead = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const result = {
      personDate: `${formData.year}-${formData.month}-${formData.day} ${formData.hours}:${formData.minutes}`,
      lng: 42.6977,
      lat: 23.3219,
    };

    // Log the result (you can handle it differently, e.g., pass it to a parent component)

    try {
      const response = await axios.post(
        "https://y68xeu846l.execute-api.eu-central-1.amazonaws.com/dev/admin/monthly/read",
        result
      );

      setMonthRead({
        upcomingMonth: response.data.currConnTexts,
        lastMonth: response.data.prevConnTexts,
        prevDate: response.data.prevDate,
        currDate: response.data.currDate,
        nextDate: response.data.nextDate,
        currDateRave: response.data.currConnection,
        prevDateRave: response.data.prevConnection,
      });

      //   console.log("POST request response:", response.data);
    } catch (error) {
      console.error("Error sending POST request:", error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    const savedUserData = localStorage.getItem("user");
    if (savedUserData) {
      setFormData(JSON.parse(savedUserData));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    for (let key in formData) {
      if (formData[key] === "") {
        alert("Моля попълнетее всички полета.");
        setIsLoading(false);
        return;
      }
    }

    localStorage.setItem("user", JSON.stringify(formData));

    await handlePriorityChannels(e);
    await handleMonthRead(e);
    setIsLoading(false);
  };

  useEffect(() => {
    if (
      monthRead.upcomingMonth !== undefined &&
      monthRead.lastMonth !== undefined &&
      raveRead !== undefined
    ) {
      navigate("/period", {
        state: {
          monthRead: monthRead,
          raveRead: raveRead,
          personDate: `${formData.year}-${formData.month}-${formData.day} ${formData.hours}:${formData.minutes}`,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    monthRead,
    monthRead.upcomingMonth,
    monthRead.lastMonth,
    raveRead,
    navigate,
  ]);

  return isLoading ? (
    <Container className="d-flex align-items-center justify-content-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  ) : (
    <form
      onSubmit={handleSubmit}
      className="space-y-6 p-4 bg-white shadow-md rounded-md max-w-lg mx-auto"
    >
      <div>
        <label className="block text-lg font-medium text-gray-700">Име</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="bg-gray-100 mt-2 block w-full p-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg"
        />
      </div>
      <div className="grid grid-cols-3 gap-6">
        <div>
          <label className="block text-lg font-medium text-gray-700">
            Ден (ДД)
          </label>
          <input
            type="number"
            name="day"
            value={formData.day}
            onChange={handleChange}
            className="bg-gray-100 mt-2 block w-full p-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg"
          />
        </div>
        <div>
          <label className="block text-lg font-medium text-gray-700">
            Месец (ММ)
          </label>
          <input
            type="number"
            name="month"
            value={formData.month}
            onChange={handleChange}
            className="bg-gray-100 mt-2 block w-full p-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg"
          />
        </div>
        <div>
          <label className="block text-lg font-medium text-gray-700">
            Година (ГГГГ)
          </label>
          <input
            type="number"
            name="year"
            value={formData.year}
            onChange={handleChange}
            className="bg-gray-100 mt-2 block w-full p-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6">
        <div>
          <label className="block text-lg font-medium text-gray-700">
            Час (ЧЧ)
          </label>
          <input
            type="number"
            name="hours"
            value={formData.hours}
            onChange={handleChange}
            className="bg-gray-100 mt-2 block w-full p-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg"
          />
        </div>
        <div>
          <label className="block text-lg font-medium text-gray-700">
            Минути (ММ)
          </label>
          <input
            type="number"
            name="minutes"
            value={formData.minutes}
            onChange={handleChange}
            className="bg-gray-100 mt-2 block w-full p-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg"
          />
        </div>
      </div>
      <div>
        <label className="block text-lg font-medium text-gray-700">
          Държава
        </label>

        <input
          disabled
          type="text"
          name="country"
          value={"Bulgaria"}
          onChange={handleChange}
          className="mt-2 block w-full p-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg"
        />
      </div>
      <div>
        <label className="block text-lg font-medium text-gray-700">Град</label>
        <input
          type="text"
          name="city"
          value={"Sofia"}
          onChange={handleChange}
          className="mt-2 block w-full p-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg"
        />
      </div>
      <div>
        <button
          type="submit"
          className="w-full py-3 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Продължи
        </button>
      </div>
    </form>
  );
};

export default BirthForm;
