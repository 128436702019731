import React from "react";
import { HexagramChartComponent } from "./HexagramChartComponent";
import { planetColors } from "../consts/planetChartPositions";

const findOccurrences = (data, targetLayer, targetGate) => {
  const result = [];

  const searchArray = (arr, category) => {
    arr.forEach((item) => {
      const [layer, gate] = item.split(".").map(Number);
      if (layer === targetLayer && gate === targetGate) {
        result.push({ category, value: item });
      }
    });
  };

  for (const [category, subCategories] of Object.entries(data)) {
    for (const [subCategory, arr] of Object.entries(subCategories)) {
      searchArray(arr, `${category}.${subCategory}`);
    }
  }

  return result;
};

export const HexagramChart = ({ layers = 13, width, height, data }) => {
  const gates = [
    7, 33, 31, 56, 62, 53, 39, 52, 15, 12, 45, 35, 16, 20, 8, 23, 2, 24, 27, 3,
    42, 51, 21, 17, 25, 36, 22, 63, 37, 55, 30, 49, 13, 19, 41, 60, 61, 54, 38,
    58, 10, 11, 26, 5, 9, 34, 14, 43, 1, 44, 28, 50, 32, 57, 48, 18, 46, 6, 47,
    64, 40, 59, 29, 4,
  ];

  const baseRadius = 220; // Base radius for the innermost layer
  const radiusIncrement = 35; // Adjust increment to fit within max radius
  const maxComponentSize = 45; // Maximum size for components in the outermost layer

  const hexagrams = [];
  // Layers + 1 to add the numbers relevant to the gates
  for (let layer = 0; layer < layers; layer++) {
    const currentRadius =
      layer <= 6
        ? baseRadius + layer * radiusIncrement + 5
        : baseRadius + 6 * radiusIncrement + (layer - 6) * 45;
    const angleStep = (2 * Math.PI) / gates.length;
    const reversedLayers = layers - layer;
    const size = maxComponentSize - reversedLayers * 2; // Increase size for outer layers

    gates.forEach((gate, index) => {
      const angle = index * angleStep;
      const x = currentRadius * Math.cos(angle);
      const y = currentRadius * Math.sin(angle);
      const rotation = (angle * 180) / Math.PI + 90; // Rotate to face outwards
      hexagrams.push({
        gate,
        transform: `translate(${x}, ${y}) rotate(${rotation})`,
        size,
        layer,
        isLast: layer === layers - 1,
      });
    });
  }

  const findMatchingGates = hexagrams
    .map(({ gate, transform, size, layer, isLast }, index) => {
      const matches = findOccurrences(data, layer, gate).map((el) => el.value);

      const matchGate = matches.length && matches[0].split(".")[1];
      return matchGate;
    })
    .filter(Boolean)
    .map(Number);

  const matchingGates = [...new Set(findMatchingGates)];

  return (
    <svg
      width={width ? width : "100%"}
      height={height ? height : "100%"}
      viewBox="-800 -800 1600 1600"
    >
      {hexagrams.map(({ gate, transform, size, layer, isLast }, index) => {
        const matches = findOccurrences(data, layer, gate).map(
          (el) => el.value
        );

        const matchGate = matches.length && matches[0].split(".")[1];

        const matchLayer = matches.length && matches[0].split(".")[0];
        const linesToColor = matches.length
          ? matches.map((v) => ({
              line: +v.split(".")[2],
              exaltedOrDetriment: +v.split(".")[3],
            }))
          : [];

        return (
          <HexagramChartComponent
            key={index}
            gate={gate}
            matchGate={matchingGates.includes(gate)}
            //line={1} // or your logic for the line
            //exaltedOrDetriment={true}
            transform={transform}
            width={size}
            height={size}
            isLast={isLast}
            //   defaultColor={planetColors[12 - layer]}
            defaultColor={
              matchLayer && +matchLayer === layer ? "black" : "#D3D3D3"
            }
            multipleLinesToColor={linesToColor}
          />
        );
      })}
    </svg>
  );
};
