import "./App.css";
import AppRouter from "./AppRouter";
import { AccessContextProvider } from "./context/AccessContext";

function App() {
  return (
    <AccessContextProvider>
      <div className="min-h-screen flex flex-col">
        <header className="bg-blue-600 text-white p-4"></header>
        <main className="flex-1 p-4 bg-gray-100 flex justify-center items-center">
          <div className="container mx-auto">
            <AppRouter />
          </div>
        </main>
        <footer className="bg-blue-600 text-white p-4"></footer>
      </div>
    </AccessContextProvider>
  );
}

export default App;
