import React from "react";
import HexagramComponent from "./HexagramComponent";
import { planetsSymbols } from "../consts/planetSymbols";

export default function HexagramPicker({
  value,
  hideGateAndPlanet,
  width,
  height,
}) {
  const valueToArray = value.split(".");
  const [planet, gate, line, exaltedOrDetriment] = valueToArray;

  return (
    <div className="flex items-center space-x-2">
      {!hideGateAndPlanet ? (
        <div className="flex items-center justify-center w-12 h-12 font-bold text-3xl">
          {planetsSymbols[+planet]}
        </div>
      ) : null}
      <div className="flex items-center justify-center flex-col space-x-2">
        <HexagramComponent
          gate={+gate}
          line={+line}
          exaltedOrDetriment={+exaltedOrDetriment}
          width={width}
          height={height}
        />
        {!hideGateAndPlanet ? gate : ""}
      </div>
    </div>
  );
}
