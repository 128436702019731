import React, { createContext, useContext, useState } from "react";
import axios from "axios";

const AccessContext = createContext();

export function AccessContextProvider({ children }) {
  const [hasAccess, setHassAccess] = useState(false);

  const handleAccess = async (password) => {
    try {
      const config = {
        method: "post",
        url: "https://y68xeu846l.execute-api.eu-central-1.amazonaws.com/dev/admin/canAccess",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          password,
        },
      };

      const response = await axios(config);

      setHassAccess(response.data.canAccess);
    } catch (error) {
      console.error("Error performing admin post:", error);
    }
  };

  return (
    <AccessContext.Provider value={{ hasAccess, handleAccess }}>
      {children}
    </AccessContext.Provider>
  );
}

export function useAccessContext() {
  return useContext(AccessContext);
}
