import React, { useState } from "react";
import { Row, Col, Container, Form, Button, Spinner } from "react-bootstrap";
import { useAccessContext } from "../context/AccessContext";

function Authentication() {
  const { handleAccess } = useAccessContext();
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    await handleAccess(password);
    setPassword("");
    setIsLoading(false);
  };

  return isLoading ? (
    <Container className="d-flex align-items-center justify-content-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  ) : (
    <Container className="d-flex align-items-center justify-content-center">
      <Row>
        <Col className="text-center">
          <Form>
            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={handlePasswordChange}
              />
            </Form.Group>

            <Button variant="primary" onClick={handleSubmit} block>
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Authentication;
