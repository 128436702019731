export const planetChartPositions = {
  Sun: 0,
  Earth: 1,
  Moon: 2,
  NorthNode: 3,
  SouthNode: 4,
  Mercury: 5,
  Venus: 6,
  Mars: 7,
  Jupiter: 8,
  Saturn: 9,
  Uranus: 10,
  Neptune: 11,
  Pluto: 12,
};

export const planetChartPositionsReversed = {
  Sun: 12,
  Earth: 11,
  Moon: 10,
  NorthNode: 9,
  SouthNode: 8,
  Mercury: 7,
  Venus: 6,
  Mars: 5,
  Jupiter: 4,
  Saturn: 3,
  Uranus: 2,
  Neptune: 1,
  Pluto: 0,
};

export const layersToPlanet = {
  0: "Sun",
  1: "Earth",
  2: "Moon",
  3: "NorthNode",
  4: "SouthNode",
  5: "Mercury",
  6: "Venus",
  7: "Mars",
  8: "Jupiter",
  9: "Saturn",
  10: "Uranus",
  11: "Neptune",
  12: "Pluto",
};

export const planetColors = {
  0: "#FFD700",
  1: "#6B8E23",
  2: "#C0C0C0",
  3: "#00008B",
  4: "#FFFFE0",
  5: "#FFA500",
  6: "#00FF00",
  7: "#FF0000",
  8: "#0000FF",
  9: "#00008B",
  10: "#7DF9FF",
  11: "#7FFFD4",
  12: "#8B0000",
};
