export const calculateWidth = (maxWidth, isSmall) => {
  const multiplyBy = isSmall ? 30 : 80;
  return (maxWidth / 100) * multiplyBy;
};

export const calculateX = (maxWidth, isSecond) => {
  const multiplyBy = isSecond ? 60 : 10;
  return (maxWidth / 100) * multiplyBy;
};

export const calculateY = (maxHeight, defaultPosition) => {
  return (maxHeight / 100) * +defaultPosition;
};

export const getLineColor = (exaltOrDetrimentNumber) => {
  switch (exaltOrDetrimentNumber) {
    case 0:
      return "blue";
    case 1:
      return "green";
    case 2:
      return "red";
    case 3:
      return "purple";
    default:
      return "black";
  }
};

export const defineLineToColor = (line) => {
  switch (line) {
    case 1:
      return { bottomLine: 3, topLine: undefined };
    case 2:
      return { bottomLine: 2, topLine: undefined };
    case 3:
      return { bottomLine: 1, topLine: undefined };
    case 4:
      return { bottomLine: undefined, topLine: 3 };
    case 5:
      return { bottomLine: undefined, topLine: 2 };
    case 6:
      return { bottomLine: undefined, topLine: 1 };
    default:
      return { bottomLine: undefined, topLine: undefined };
  }
};
