import Chen from "./Chen";
import Chien from "./Chien";
import Kan from "./Kan";
import Ken from "./Ken";
import Kun from "./Kun";
import Li from "./Li";
import Sun from "./Sun";
import Tui from "./Tui";

export const gateHexagrams = {
  1: { top: Chien, bottom: Chien },
  2: { top: Kun, bottom: Kun },
  3: { top: Kan, bottom: Chen },
  4: { top: Ken, bottom: Kan },
  5: { top: Kan, bottom: Chien },
  6: { top: Chien, bottom: Kan },
  7: { top: Kun, bottom: Kan },
  8: { top: Kan, bottom: Kun },
  9: { top: Sun, bottom: Chien },
  10: { top: Chien, bottom: Tui },
  11: { top: Kun, bottom: Chien },
  12: { top: Chien, bottom: Kun },
  13: { top: Chien, bottom: Li },
  14: { top: Li, bottom: Chien },
  15: { top: Kun, bottom: Ken },
  16: { top: Chen, bottom: Kun },
  17: { top: Tui, bottom: Chen },
  18: { top: Ken, bottom: Sun },
  19: { top: Kun, bottom: Tui },
  20: { top: Sun, bottom: Kun },
  21: { top: Li, bottom: Chen },
  22: { top: Ken, bottom: Li },
  23: { top: Ken, bottom: Kun },
  24: { top: Kun, bottom: Chen },
  25: { top: Chien, bottom: Chen },
  26: { top: Ken, bottom: Chien },
  27: { top: Ken, bottom: Chen },
  28: { top: Tui, bottom: Sun },
  29: { top: Kan, bottom: Kan },
  30: { top: Li, bottom: Li },
  31: { top: Tui, bottom: Ken },
  32: { top: Chen, bottom: Sun },
  33: { top: Chien, bottom: Ken },
  34: { top: Chen, bottom: Chien },
  35: { top: Li, bottom: Kun },
  36: { top: Kun, bottom: Li },
  37: { top: Sun, bottom: Li },
  38: { top: Li, bottom: Tui },
  39: { top: Kan, bottom: Ken },
  40: { top: Chen, bottom: Kan },
  41: { top: Ken, bottom: Tui },
  42: { top: Sun, bottom: Chen },
  43: { top: Tui, bottom: Chien },
  44: { top: Chien, bottom: Sun },
  45: { top: Tui, bottom: Kun },
  46: { top: Kun, bottom: Sun },
  47: { top: Tui, bottom: Kan },
  48: { top: Kan, bottom: Sun },
  49: { top: Tui, bottom: Li },
  50: { top: Li, bottom: Sun },
  51: { top: Chen, bottom: Chen },
  52: { top: Ken, bottom: Ken },
  53: { top: Sun, bottom: Ken },
  54: { top: Chen, bottom: Tui },
  55: { top: Chen, bottom: Li },
  56: { top: Li, bottom: Ken },
  57: { top: Sun, bottom: Sun },
  58: { top: Tui, bottom: Tui },
  59: { top: Sun, bottom: Kan },
  60: { top: Kan, bottom: Tui },
  61: { top: Sun, bottom: Tui },
  62: { top: Chen, bottom: Ken },
  63: { top: Kan, bottom: Li },
  64: { top: Li, bottom: Kan },
};
