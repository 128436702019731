import { planetChartPositionsReversed } from "../consts/planetChartPositions";

const exaltedOrDetriment = (data) => {
  if (data.fixed) {
    // both
    return "3";
  }
  if (data.exalted) {
    return "1";
  }
  if (data.detriment) {
    return "2";
  }
  return "0";
};

const sortArrayByFirstNumber = (arr) => {
  return arr.sort((a, b) => {
    const firstNumberA = parseInt(a.split(".")[0]);
    const firstNumberB = parseInt(b.split(".")[0]);
    return firstNumberA - firstNumberB;
  });
};

export const mapRaveToHexagramChartData = (rave) => {
  const splitArray = (arr) => {
    const half = Math.ceil(arr.length / 2);
    const firstHalf = arr.slice(0, half);
    const secondHalf = arr.slice(half);
    return [firstHalf, secondHalf];
  };

  const [personData, transitData] = splitArray(rave.activations);
  const personDesign = personData
    .filter((data) => data.type === "Design")
    .map(
      (data) =>
        `${planetChartPositionsReversed[data.planet]}.${data.gate}.${
          data.line
        }.${exaltedOrDetriment(data)}`
    );
  const transitDesign = transitData
    .filter((data) => data.type === "Design")
    .map(
      (data) =>
        `${planetChartPositionsReversed[data.planet]}.${data.gate}.${
          data.line
        }.${exaltedOrDetriment(data)}`
    );

  const personPersonality = personData
    .filter((data) => data.type === "Personality")
    .map(
      (data) =>
        `${planetChartPositionsReversed[data.planet]}.${data.gate}.${
          data.line
        }.${exaltedOrDetriment(data)}`
    );
  const transitPersonality = transitData
    .filter((data) => data.type === "Personality")
    .map(
      (data) =>
        `${planetChartPositionsReversed[data.planet]}.${data.gate}.${
          data.line
        }.${exaltedOrDetriment(data)}`
    );

  return {
    person: {
      design: sortArrayByFirstNumber(personDesign),
      personality: sortArrayByFirstNumber(personPersonality),
    },
    transit: {
      design: sortArrayByFirstNumber(transitDesign),
      personality: sortArrayByFirstNumber(transitPersonality),
    },
  };
};
