export const formatDate = (dateStr) => {
  const date = new Date(dateStr);

  // Extract the day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  // Format the date as dd-mm-yyyy
  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
};

export const formatDateHoursMins = (dateStr) => {
  const date = new Date(dateStr);

  // Extract the day, month, year, hours, and minutes
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Format the date and time as dd-mm-yyyy hh:mm
  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

  return formattedDate;
};

export function getUTCDate(dateStr) {
  // Split the date and time parts
  const [datePart, timePart] = dateStr.split(" ");

  // Split the date part into day, month, and year
  const [day, month, year] = datePart.split("-").map(Number);

  // Split the time part into hours and minutes
  const [hours, minutes] = timePart.split(":").map(Number);

  // Create a Date object using the local time
  const date = new Date(year, month - 1, day, hours, minutes);

  // Extract the UTC components
  const utcDay = String(date.getUTCDate()).padStart(2, "0");
  const utcMonth = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const utcYear = date.getUTCFullYear();
  const utcHours = String(date.getUTCHours()).padStart(2, "0");
  const utcMinutes = String(date.getUTCMinutes()).padStart(2, "0");

  // Format the date as yyyy-mm-dd hh:mm:ss UTC
  const formattedUTCDate = `${utcDay}-${utcMonth}-${utcYear} ${utcHours}:${utcMinutes} UTC`;

  return formattedUTCDate;
}
