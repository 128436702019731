export const getNowDate = () => {
  const todayDate = new Date();
  const year = todayDate.getFullYear();
  const month = (todayDate.getMonth() + 1).toString().padStart(2, "0");
  const day = todayDate.getDate().toString().padStart(2, "0");
  const hours = todayDate.getHours().toString().padStart(2, "0");
  const minutes = todayDate.getMinutes().toString().padStart(2, "0");

  const justNowDate = `${year}-${month}-${day} ${hours}:${minutes}`;
  return justNowDate;
};

export const getTodayDate = () => {
  const todayDate = new Date();
  const year = todayDate.getFullYear();
  const month = (todayDate.getMonth() + 1).toString().padStart(2, "0");
  const day = todayDate.getDate().toString().padStart(2, "0");

  const todayDateStr = `${year}-${month}-${day}`;
  return todayDateStr;
};

export const getTomorrowDate = () => {
  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  const year = tomorrowDate.getFullYear();
  const month = (tomorrowDate.getMonth() + 1).toString().padStart(2, "0");
  const day = tomorrowDate.getDate().toString().padStart(2, "0");

  const tomorrowDateStr = `${year}-${month}-${day}`;
  return tomorrowDateStr;
};
