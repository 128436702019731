import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import PeriodPage from "./pages/PeriodPage";
import BirthForm from "./pages/BirthForm";
import DetailPage from "./pages/DetailPage";
import AspectsList from "./pages/AspectsList";
import { useAccessContext } from "./context/AccessContext";
import Authentication from "./pages/Authentication";
import DailyRead from "./pages/DailyRead";
import DailyReadDetails from "./pages/DailyReadDetails";
import HexagramChartDetails from "./pages/HexagramChartDetails";
import EducationalAspectsList from "./pages/EducationalAspectsList";

const AppRouter = () => {
  const { hasAccess } = useAccessContext();

  if (!hasAccess) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Authentication />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<BirthForm />} />
        <Route path="/period" element={<PeriodPage />} />
        <Route path="/detail" element={<DetailPage />} />
        <Route path="/aspects/:name" element={<AspectsList />} />
        <Route
          path="/educationalAspects/:name"
          element={<EducationalAspectsList />}
        />
        <Route path="/dailyRead" element={<DailyRead />} />
        <Route path="/dailyRead/:name" element={<DailyReadDetails />} />
        <Route path="/chartDetails" element={<HexagramChartDetails />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
