import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HexagramPicker from "../components/HexagramPicker";
import { panetSymbolsForReversedOrder } from "../consts/planetSymbols";
import { reverseArraysInObject } from "../utils/reverseArraysInObject";
import { getUserTimeZone } from "../utils/getUserTimezone";
import { getUTCDate } from "../utils/formatDate";

function HexagramChartDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, transitDate } = location.state || {};

  const reversedArraysData = reverseArraysInObject(data);
  const personData = JSON.parse(localStorage.getItem("user"));

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="mt-10 space-y-4">
      <div className="flex flex-col items-center justify-center space-y-2">
        <button onClick={goBack} className="text-blue-500 hover:underline">
          {"<-"}Назад
        </button>
      </div>
      <div className="flex flex-col lg:flex-row items-center justify-evenly space-y-5">
        <div className="flex flex-col items-center justify-center space-y-2">
          <div className="flex flex-row items-center justify-center">
            <div className="flex flex-col items-center justify-center ">
              {reversedArraysData.person.design.map((v) => (
                <div className="flex flex-row items-center justify-center space-x-2">
                  <div style={{ width: "50px", textAlign: "center" }}>
                    {v.split(".")[1]}
                  </div>
                  <HexagramPicker
                    value={v}
                    hideGateAndPlanet
                    width={30}
                    height={30}
                  />
                </div>
              ))}
            </div>
            <div className="flex flex-col items-center justify-center">
              {Object.entries(panetSymbolsForReversedOrder)
                .reverse()
                .map(([key, planet]) => (
                  <div
                    className="flex flex-row items-center justify-center px-3"
                    style={{ fontSize: "20px" }}
                  >
                    {planet}
                  </div>
                ))}
            </div>
            <div className="flex flex-col items-center justify-center">
              {reversedArraysData.person.personality.map((v) => (
                <div className="flex flex-row items-center justify-center space-x-2">
                  <HexagramPicker
                    value={v}
                    hideGateAndPlanet
                    width={30}
                    height={30}
                  />
                  <div style={{ width: "50px", textAlign: "center" }}>
                    {v.split(".")[1]}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>Име: {personData.name}</div>
          <div>
            Дата: {personData.day}-{personData.month}-{personData.year}{" "}
            {personData.hours}:{personData.minutes}
          </div>
          <div>
            Родно място: {personData.city},{personData.country}
          </div>
        </div>

        <div className="flex flex-col items-center justify-center space-y-2 mt-0">
          <div className="flex flex-row items-center justify-center">
            <div className="flex flex-col items-center justify-center ">
              {reversedArraysData.transit.design.map((v) => (
                <div className="flex flex-row items-center justify-center space-x-2">
                  <div style={{ width: "50px", textAlign: "center" }}>
                    {v.split(".")[1]}
                  </div>
                  <HexagramPicker
                    value={v}
                    hideGateAndPlanet
                    width={30}
                    height={30}
                  />
                </div>
              ))}
            </div>
            <div className="flex flex-col items-center justify-center">
              {Object.entries(panetSymbolsForReversedOrder)
                .reverse()
                .map(([key, planet]) => (
                  <div
                    className="flex flex-row items-center justify-center px-3"
                    style={{ fontSize: "20px" }}
                  >
                    {planet}
                  </div>
                ))}
            </div>
            <div className="flex flex-col items-center justify-center">
              {reversedArraysData.transit.personality.map((v) => (
                <div className="flex flex-row items-center justify-center space-x-2">
                  <HexagramPicker
                    value={v}
                    hideGateAndPlanet
                    width={30}
                    height={30}
                  />
                  <div style={{ width: "50px", textAlign: "center" }}>
                    {v.split(".")[1]}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>Дата: {transitDate}</div>
          <div>Дата UTC: {getUTCDate(transitDate)}</div>
          <div>Времева зона: {getUserTimeZone()}</div>
        </div>
      </div>
    </div>
  );
}

export default HexagramChartDetails;
