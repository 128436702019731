// src/PeriodPage.js
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getTodayDate, getTomorrowDate } from "../utils/getNowDate";
import { formatDate, formatDateHoursMins } from "../utils/formatDate";
import axios from "axios";
import { Container, Spinner } from "react-bootstrap";

export default function PeriodPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { monthRead, personDate } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);

  // const handleJustNow = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);

  //   const todayDate = new Date();
  //   const year = todayDate.getFullYear();
  //   const month = (todayDate.getMonth() + 1).toString().padStart(2, "0");
  //   const day = todayDate.getDate().toString().padStart(2, "0");
  //   const hours = todayDate.getHours().toString().padStart(2, "0");
  //   const minutes = todayDate.getMinutes().toString().padStart(2, "0");

  //   const justNowDate = `${year}-${month}-${day} ${hours}:${minutes}`;

  //   const result = {
  //     personDate: `${personDate}`,
  //     transitDate: `${justNowDate}`,
  //     lng: 42.6977,
  //     lat: 23.3219,
  //     final: raveRead,
  //   };

  //   try {
  //     const response = await axios.post(
  //       "https://y68xeu846l.execute-api.eu-central-1.amazonaws.com/dev/admin/connection/read",
  //       result
  //     );

  //     setIsLoading(false);
  //     navigate("/detail", {
  //       state: {
  //         period: `Вихърът на Настоящето. Почувствайте пулса от обуславяния днес: ${getNowDate()}`,
  //         data: response.data.gates,
  //         startDate: getNowDate(),
  //         endDate: getNowDate(),
  //         timeText: "now",
  //       },
  //     });
  //     //   console.log("POST request response:", response.data);
  //   } catch (error) {
  //     console.error("Error sending POST request:", error);
  //     setIsLoading(false);
  //   }
  // };

  const handleDailyRead = async (e, isTomorrow) => {
    e.preventDefault();
    setIsLoading(true);

    const todayDateStr = getTodayDate();
    const tomorrowDateStr = getTomorrowDate();

    const body = {
      date: `${isTomorrow ? tomorrowDateStr : todayDateStr}T00:00:00.000Z`,
      personDate: `${personDate}`,
    };

    try {
      const response = await axios.post(
        "https://y68xeu846l.execute-api.eu-central-1.amazonaws.com/dev/admin/dailyRead",
        body
      );

      setIsLoading(false);
      navigate("/dailyRead", {
        state: {
          period: isTomorrow
            ? `Утре ${tomorrowDateStr}`
            : `Вихърът на Настоящето. Почувствайте пулса от обуславяния днес: ${todayDateStr}`,
          data: response.data,
          date: isTomorrow ? tomorrowDateStr : todayDateStr,
          timeText: "now",
        },
      });
    } catch (error) {
      console.error("Error sending POST request:", error);
      setIsLoading(false);
    }
  };

  const handleClickMonthlyRead = (period) => {
    const periodData = {
      lastMonth: {
        period: `Вихрите на Историята. Разкрийте скритите истории и влияния от периода ${formatDate(
          monthRead.prevDate
        )} до ${formatDate(monthRead.currDate)}`,
        data: monthRead.lastMonth.gates,
        startDate: formatDate(monthRead.prevDate),
        startDateHoursMins: formatDateHoursMins(monthRead.prevDate),
        endDate: `до ${formatDate(monthRead.currDate)}`,
        timeText: "before",
        rave: monthRead.prevDateRave,
      },
      upcomingMonth: {
        period: `Преходът към Утре. Не пропускайте непрестанния поток от обуславяния и
        влиянието им в периода ${formatDate(
          monthRead.currDate
        )} до ${formatDate(monthRead.nextDate)}`,
        data: monthRead.upcomingMonth.gates,
        startDate: formatDate(monthRead.currDate),
        startDateHoursMins: formatDateHoursMins(monthRead.currDate),
        endDate: `до ${formatDate(monthRead.nextDate)}`,
        timeText: "future",
        rave: monthRead.currDateRave,
        educational: monthRead.upcomingMonth.educational,
      },
    };

    navigate("/detail", { state: periodData[period] });
  };

  return isLoading ? (
    <Container className="d-flex align-items-center justify-content-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  ) : (
    <div className="flex flex-col md:flex-row justify-around items-center mt-10 space-y-4 md:space-y-0 md:space-x-4">
      <div
        onClick={() => handleClickMonthlyRead("lastMonth")}
        className="cursor-pointer p-8 w-full md:w-64 h-52 bg-blue-500 text-white text-center rounded-lg shadow-lg hover:bg-blue-700 flex items-center justify-center"
      >
        Вихрите на Историята. Разкрийте скритите истории и влияния от периода{" "}
        {formatDate(monthRead.prevDate)} до {formatDate(monthRead.currDate)}
      </div>
      <div
        onClick={() => handleClickMonthlyRead("upcomingMonth")}
        className="cursor-pointer p-8 w-full md:w-64 h-52 bg-blue-500 text-white text-center rounded-lg shadow-lg hover:bg-blue-700 flex items-center justify-center"
      >
        Преходът към Утре. Не пропускайте непрестанния поток от обуславяния и
        влиянието им в периода {formatDate(monthRead.currDate)} до{" "}
        {formatDate(monthRead.nextDate)}
      </div>
      <div
        onClick={(e) => handleDailyRead(e)}
        className="cursor-pointer p-8 w-full md:w-64 h-52 bg-blue-500 text-white text-center rounded-lg shadow-lg hover:bg-blue-700 flex flex-col items-center justify-center"
      >
        <span>
          Вихърът на Настоящето. Почувствайте пулса от обуславяния днес:
        </span>
        <span className="block">{getTodayDate()}</span>
      </div>
      <div
        onClick={(e) => handleDailyRead(e, true)}
        className="cursor-pointer p-8 w-full md:w-64 h-52 bg-blue-500 text-white text-center rounded-lg shadow-lg hover:bg-blue-700 flex flex-col items-center justify-center"
      >
        <span>Утре</span>
        <span className="block">{getTomorrowDate()}</span>
      </div>
    </div>
  );
}
