import React from "react";
import { calculateWidth, calculateX, calculateY, getLineColor } from "./utils";

const Ken = ({
  isSecond,
  maxWidth,
  maxHeight,
  line,
  exaltedOrDetriment,
  defaultColor,
  multipleLinesToColor = [],
}) => (
  <>
    <rect
      y={isSecond ? calculateY(maxHeight, "55") : calculateY(maxHeight, "10")}
      width={calculateWidth(maxWidth)}
      height={maxHeight / 10}
      x={calculateX(maxWidth)}
      fill={
        line === 1 ||
        (multipleLinesToColor &&
          multipleLinesToColor.length &&
          multipleLinesToColor.includes(1))
          ? getLineColor(
              exaltedOrDetriment.length > 0
                ? exaltedOrDetriment[multipleLinesToColor.indexOf(1)]
                : exaltedOrDetriment
            )
          : defaultColor
          ? defaultColor
          : "black"
      }
    />
    <rect
      y={isSecond ? calculateY(maxHeight, "70") : calculateY(maxHeight, "25")}
      width={calculateWidth(maxWidth, true)}
      height={maxHeight / 10}
      x={calculateX(maxWidth)}
      fill={
        line === 2 ||
        (multipleLinesToColor &&
          multipleLinesToColor.length &&
          multipleLinesToColor.includes(2))
          ? getLineColor(
              exaltedOrDetriment.length > 0
                ? exaltedOrDetriment[multipleLinesToColor.indexOf(2)]
                : exaltedOrDetriment
            )
          : defaultColor
          ? defaultColor
          : "black"
      }
    />
    <rect
      y={isSecond ? calculateY(maxHeight, "70") : calculateY(maxHeight, "25")}
      width={calculateWidth(maxWidth, true)}
      height={maxHeight / 10}
      x={calculateX(maxWidth, true)}
      fill={
        line === 2 ||
        (multipleLinesToColor &&
          multipleLinesToColor.length &&
          multipleLinesToColor.includes(2))
          ? getLineColor(
              exaltedOrDetriment.length > 0
                ? exaltedOrDetriment[multipleLinesToColor.indexOf(2)]
                : exaltedOrDetriment
            )
          : defaultColor
          ? defaultColor
          : "black"
      }
    />
    <rect
      y={isSecond ? calculateY(maxHeight, "85") : calculateY(maxHeight, "40")}
      width={calculateWidth(maxWidth, true)}
      height={maxHeight / 10}
      x={calculateX(maxWidth)}
      fill={
        line === 3 ||
        (multipleLinesToColor &&
          multipleLinesToColor.length &&
          multipleLinesToColor.includes(3))
          ? getLineColor(
              exaltedOrDetriment.length > 0
                ? exaltedOrDetriment[multipleLinesToColor.indexOf(3)]
                : exaltedOrDetriment
            )
          : defaultColor
          ? defaultColor
          : "black"
      }
    />
    <rect
      y={isSecond ? calculateY(maxHeight, "85") : calculateY(maxHeight, "40")}
      width={calculateWidth(maxWidth, true)}
      height={maxHeight / 10}
      x={calculateX(maxWidth, true)}
      fill={
        line === 3 ||
        (multipleLinesToColor &&
          multipleLinesToColor.length &&
          multipleLinesToColor.includes(3))
          ? getLineColor(
              exaltedOrDetriment.length > 0
                ? exaltedOrDetriment[multipleLinesToColor.indexOf(3)]
                : exaltedOrDetriment
            )
          : defaultColor
          ? defaultColor
          : "black"
      }
    />
  </>
);

export default Ken;
