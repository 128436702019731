export const planetsSymbols = {
  1: "☉",
  7: "⊕",
  3: "☊",
  4: "☋",
  2: "☽",
  5: "☿",
  6: "♀",
  8: "♂",
  9: "♃",
  10: "♄",
  11: "♅",
  12: "♆",
  13: "♇",
};

export const panetSymbolsForReversedOrder = {
  12: "☉",
  11: "⊕",
  9: "☊",
  8: "☋",
  10: "☽",
  7: "☿",
  6: "♀",
  5: "♂",
  4: "♃",
  3: "♄",
  2: "♅",
  1: "♆",
  0: "♇",
};
