import React from "react";
import HexagramComponent from "./HexagramComponent";

export default function HexagramPickerNoPlanet({
  value,
  hideGateAndPlanet,
  width,
  height,
}) {
  const valueToArray = value.split(".");
  const [gate, line, exaltedOrDetriment] = valueToArray;

  return (
    <div className="flex items-center space-x-2">
      <div className="flex items-center justify-center flex-col space-x-2">
        <HexagramComponent
          gate={+gate}
          line={+line}
          exaltedOrDetriment={+exaltedOrDetriment}
          width={width}
          height={height}
        />
        {!hideGateAndPlanet ? gate : ""}
      </div>
    </div>
  );
}
